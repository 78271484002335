import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export interface CalendarState {
  moveVisit: {
    state: 'idle' | 'moving'
    visitId: string
    sourceRoute: string
    sourceFrom: number
    targetRoute: string
    targetFrom: number
  }
  mode: 'selection' | 'editing'
  date: string
  visitEditorOpen: boolean
  selectedVisit: string
  selectedGroup: string
  selectedRoutes: string[]
  legacyGrouping: boolean
  scale: number
  gutterInterval: number
}

export const defaultInitialState: CalendarState = {
  moveVisit: {
    state: 'idle',
    visitId: '',
    sourceRoute: '',
    sourceFrom: 0,
    targetRoute: '',
    targetFrom: 0,
  },
  mode: 'editing',
  date: '2022-01-01',
  visitEditorOpen: false,
  selectedVisit: '',
  selectedGroup: '',
  selectedRoutes: [],
  legacyGrouping: false,
  scale: 2,
  gutterInterval: 30,
}

export const createCalendarSlice = <
  Reducers extends SliceCaseReducers<CalendarState>,
>({
  name = '',
  initialState = defaultInitialState,
  reducers,
}: {
  name: string
  initialState?: CalendarState
  reducers?: ValidateSliceCaseReducers<CalendarState, Reducers>
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      beginMoveVisit: (
        state,
        action: PayloadAction<{
          visitId: string
          from: number
          routeId: string
        }>
      ) => {
        state.moveVisit = {
          state: 'moving',
          visitId: action.payload.visitId,
          sourceRoute: action.payload.routeId,
          sourceFrom: action.payload.from,
          targetRoute: action.payload.routeId,
          targetFrom: action.payload.from,
        }
      },
      endMoveVisit: (
        state,
        action: PayloadAction<{ targetFrom: number; targetRoute: string }>
      ) => {
        state.moveVisit.state = 'idle'
        state.moveVisit.targetFrom = action.payload.targetFrom
        state.moveVisit.targetRoute = action.payload.targetRoute
      },
      cancelMoveVisit: state => {
        state.moveVisit = {
          state: 'idle',
          visitId: '',
          sourceRoute: '',
          sourceFrom: 0,
          targetRoute: '',
          targetFrom: 0,
        }
      },
      moveVisitDone: state => {
        state.moveVisit = {
          state: 'idle',
          visitId: '',
          sourceRoute: '',
          sourceFrom: 0,
          targetRoute: '',
          targetFrom: 0,
        }
      },
      increment: state => {
        state.date = dayjs(state.date, 'YYYY-MM-DD')
          .add(1, 'day')
          .format('YYYY-MM-DD')
      },
      decrement: state => {
        state.date = dayjs(state.date, 'YYYY-MM-DD')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      },
      incrementByAmount: (state, action: PayloadAction<number>) => {
        state.date = dayjs(state.date, 'YYYY-MM-DD')
          .add(action.payload, 'day')
          .format('YYYY-MM-DD')
      },
      gotoToday: state => {
        state.date = dayjs().format('YYYY-MM-DD')
      },
      gotoDate: (state, action: PayloadAction<string>) => {
        state.date = dayjs(action.payload, 'YYYY-MM-DD').format('YYYY-MM-DD')
      },
      selectVisit: (state, action: PayloadAction<string>) => {
        if (state.selectedVisit === action.payload) {
          state.selectedVisit = ''
        } else {
          state.selectedVisit = action.payload
        }
        switch (state.mode) {
          case 'selection':
            state.visitEditorOpen = false
            break
          case 'editing':
            state.visitEditorOpen = action.payload !== ''
            break
        }
      },
      closeVisitEditor: state => {
        state.selectedVisit = ''
        state.visitEditorOpen = false
      },
      selectGroup: (state, action: PayloadAction<string>) => {
        if (state.selectedGroup !== action.payload) {
          state.selectedGroup = action.payload
          state.selectedRoutes = []
        }
      },
      selectRoutes: (state, action: PayloadAction<string[]>) => {
        state.selectedRoutes = action.payload
      },
      setLegacyGrouping: (state, action: PayloadAction<boolean>) => {
        state.legacyGrouping = action.payload
      },
      setScale: (state, action: PayloadAction<number>) => {
        state.scale = Math.max(0.01, Math.min(action.payload, 40))
      },
      setGutterInterval: (state, action: PayloadAction<number>) => {
        state.gutterInterval = Math.max(1, Math.min(action.payload, 12 * 60))
      },
      ...reducers,
    },
    extraReducers: builder => {
      builder.addMatcher(
        a => a.type === 'shell/appLaunched',
        state => {
          state.date = dayjs().format('YYYY-MM-DD')
        }
      )
    },
  })
}
